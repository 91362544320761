html {
  scroll-behavior: smooth;
}

body {
  @apply text-lg text-grey-600 md:text-2xl;

  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

.custom-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.25rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.pswp__default-caption {
  bottom: 0 !important;
  padding: 1rem !important;
  background-color: rgba($color: #000000, $alpha: 0.75) !important;
  text-shadow: none !important;
  font-size: 1rem !important;

  &:empty {
    display: none !important;
  }
}
